import React, { useEffect } from 'react';
import { useAuth } from "../../AuthContext";
import './NamePage.css'; // Assuming you have a CSS file for styling

function NamePage() {
    const { login } = useAuth();
    const { logout } = useAuth();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            const token = query.get('token');
            const authType = localStorage.getItem('authType');
            fetch('https://backend.channellinkup.com/api/check-subscription/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `JWT ${token}`,
                    'X-Auth-Type': authType,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.has_active_subscription) {
                        login(token);
                        console.log('User is subscribed');
                        window.location.href = "/";
                    } else {
                        console.log('User is not subscribed');
                    }
                })
                .catch(error => {
                    console.error('Error checking subscription:', error);
                });
        }

        if (query.get('canceled')) {
            logout();
            console.log('Payment canceled');
        }
    }, [login, logout]);

    return (
        <div className="centered">
            <h1 className="grey-text">Channel Linkup</h1>
        </div>
    );
}

export default NamePage;
