import React from 'react';
import Header from './Header';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';
import './Legal.css';

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-page">
        <div>
        <Header />
        <PrivacyPolicy />
        <Footer />
        </div>
    </div>
  );
};

export default PrivacyPolicyPage;
