import React, { useState } from 'react';

function Sidebar({ setActiveTab }) {
  return (
    <div className="sidebar">
      <button onClick={() => setActiveTab('projects')}>Create Project</button> {/* Add the new button */}
      <button onClick={() => setActiveTab('upload')}>Project Content</button>
      <button onClick={() => setActiveTab('editor')}>Video Editor</button>
      <button onClick={() => setActiveTab('video')}>Video Information</button> 
      <button onClick={() => setActiveTab('youtube')}>Review and Publish</button>
    </div>
  );
}

export default Sidebar;
