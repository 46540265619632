import React, { useState } from 'react';

function Sidebar({ setActiveTab }) {
  return (
    <div className="sidebar">
      <button onClick={() => setActiveTab('video-content')}>Video Content</button>
      <button onClick={() => setActiveTab('upload-video')}>Upload Video</button>
    </div>
  );
}

export default Sidebar;
