import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(() => {
      const token = localStorage.getItem('token');
      return token ? { token } : null;
    });
  
    const login = (token) => {
      setUser({ token });
      localStorage.setItem('token', token);
    }

  const logout = () => {
    // Clear user data and token from context and local storage
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('authType');
    localStorage.removeItem('uniqueHash');
    localStorage.removeItem('user');
    window.location.href = "/";
  };

  const isAuthenticated = !!user?.token; // Return a boolean value

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
