// PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container1">
      <header className="header">
        <h1>Privacy Policy</h1>
        <p>Last Updated: April 30, 2024</p>
      </header>

      <section className="section">
        <h2>Purpose of this privacy policy</h2>
        <p>This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform. Please read this policy carefully to understand how we handle your information. By using Channel Linkup, you agree to the practices described in this Privacy Policy.
        </p>
      </section>

      <section className="section">
        <h2>1. Information We Collect</h2>
        <p data-point="1.1. User-Provided Information:">When you sign up for Channel Linkup, we collect information such as your name, email address, and any additional information you choose to provide.</p>
        <p data-point="1.2. Uploaded Content:">Youtubers and Video Editors may upload raw and edited video content, respectively. This content is stored securely on our platform.</p>
        <p data-point="1.3. Log Data:">We collect information that your browser or mobile device sends when you access our platform. This may include your IP address, browser type, device information, and timestamps.</p>
        <p data-point="1.4. YouTube API Services:">Channel Linkup uses YouTube API Services to facilitate the collaboration between Youtubers and Video Editors. By using our platform, you acknowledge that your information is processed in accordance with the YouTube API Services.</p>
      </section>

      <section className="section">
        <h2>2. How We Use Your Information</h2>
        <p data-point="2.1. To Provide Services:">We use the information you provide to offer our collaborative platform services, including facilitating the collaboration between Youtubers and Video Editors.</p>
        <p data-point="2.2. Communication:">We may use your email address to send updates, notifications, and other communications related to your Channel Linkup account.</p>
        <p data-point="2.3. Improvement of Services:">We analyze user behavior and feedback to enhance and improve our platform.</p>
        <p data-point="2.4. Data Processing and Sharing:">Channel Linkup processes and shares user data internally for the purpose of providing collaborative services. User data is not shared with external parties, except as required by legal requests or to comply with applicable laws.</p>
      </section>

      <section className="section">
        <h2>3. Information Sharing and Disclosure</h2>
        <p data-point="3.1. Collaborative Sharing:">Youtubers and Video Editors can access and collaborate on content uploaded to Channel Linkup.</p>
        <p data-point="3.2. Service Providers:">We may engage third-party service providers to assist in providing and maintaining our services. These providers have limited access to user information for specific tasks.</p>
        <p data-point="3.3. Legal Compliance:">We may disclose your information in response to legal requests, court orders, or to comply with applicable laws.</p>
        <p data-point="3.4. Third-Party Content:">Channel Linkup does not allow third parties to serve content, including advertisements, on our platform.</p>
      </section>

      <section className="section">
        <h2>4. Data Security</h2>
        <p data-point="4.1. Data Security Measures:">We prioritize the security of your information and use industry-standard measures to protect against unauthorized access, disclosure, alteration, or destruction.</p>
      </section>

      <section className="section">
        <h2>5. User Control and Choices</h2>
        <p data-point="5.1. User Control and Rights">You have the right to access, correct, update, or delete your information. You can do so by accessing your account settings on Channel Linkup.</p>
        <p data-point="5.2. Revoking Access:">Users have the right to revoke Channel Linkup's access to their data via the Google security settings page at <a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noopener noreferrer">https://security.google.com/settings/security/permissions</a>.</p>
      </section>

      <section className="section">
        <h2>6. Changes to this Privacy Policy</h2>
        <p data-point="6.1. Policy Updates:">We reserve the right to update or change this Privacy Policy at any time.</p>
        <p data-point="6.2. Notification of Changes:">Users will be notified of significant changes in our Privacy Policy.</p>
      </section>

      <section className="section">
        <h2>7. Third-Party Information</h2>
        <p data-point="7.1. Google Privacy Policy:">This Privacy Policy contains a reference and link to the Google Privacy Policy at <a href="http://www.google.com/policies/privacy" target="_blank" rel="noopener noreferrer">http://www.google.com/policies/privacy</a>.</p>
      </section>

      <section className="section">
        <h2>8. Compliance with Google API Services User Data Policy</h2>
        <p data-point="8.1. Limited Use Policy Disclosure:">(Channel Linkup's) use and transfer of information received from Google APIs, including (Google's) Restricted and Sensitive Scopes, will adhere to Google API Services User Data Policy, including the Limited Use requirements. For more information, please refer to the <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">Google API Services User Data Policy</a>.</p>
      </section>

      <section className="section">
        <h2>9. Contact Information</h2>
        <p>For any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:channellinkup@gmail.com">channellinkup@gmail.com</a>.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
