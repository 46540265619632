import React from 'react';
import linkedinLogo from '../images/linkedin.svg';

function Footer() {
  const linkedInUrl = 'https://www.linkedin.com/in/vedant-sharma-5855621a5/';

  const taglineStyles = {
    fontSize: '1.2rem',
    fontFamily: 'VT323, monospace',
    color: 'orange',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  };

  const channelLinkupStyles = {
    fontSize: '1.5rem',
    fontFamily: 'VT323, monospace',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: 'orange',
  };

  const linkedinLogoStyles = {
    width: '30px',
    marginTop: '5px',
    marginRight: '10px', // Add space to the right of the logo
  };

  const linkContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '5px',
    marginBottom: '5px',
  };

  const linkStyles = {
    marginRight: '20px',
    marginLeft: '20px',
    textDecoration: 'underline',
    color: '#000000',
  };

  linkStyles[':hover'] = {
    color: 'orange', // Change the color on hover
    textDecoration: 'underline', // Add underline on hover
  };

  const rightsReservedStyles = {
    color: 'black',
    marginTop: '0px', // Add spacing above the rights reserved line
    marginBottom: '10px', // Add spacing below the rights reserved line
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <h3 style={channelLinkupStyles}>CHANNEL LINKUP</h3>
          <p style={taglineStyles}>Connecting Creativity, One Video at a Time!</p>
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={linkedinLogo}
              alt="LinkedIn"
              style={linkedinLogoStyles}
            />
          </a>
          <div style={linkContainerStyles}>
            <a href="/legal/terms-of-service" style={linkStyles}>
              Terms of Service
            </a>
            <span>|</span>
            <a href="/legal/privacy-policy" style={linkStyles}>
              Privacy Policy
            </a>
          </div>
        </div>
        <p style={rightsReservedStyles}>&copy; 2024 Channel Linkup. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
