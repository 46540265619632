import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import './Player.css';

const Player = ({ qualityUrls }) => {
  const videoElement = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Clean up the previous instance of Plyr
    if (playerRef.current) {
      playerRef.current.destroy();
    }

    // Initialize Plyr with the video element using a CSS selector
    const player = new Plyr('#video-player', {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen'],
      settings: ['captions', 'quality', 'speed', 'loop'],
      autoplay: false,
      seekTime: 10,
      volume: 1,
      muted: false,
      clickToPlay: true,
      disableContextMenu: true,
      hideControls: true,
      resetOnEnd: false,
      keyboard: {
        focused: true,
        global: false
      },
      tooltips: {
        controls: false,
        seek: true
      },
      storage: {
        enabled: true,
        key: 'plyr'
      },
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.5, 2]
      },
      quality: {
        default: 360,
        options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240, 144]
      },
      loop: {
        active: false
      }
    });

    // Set the video source based on the qualityUrls prop
    if (qualityUrls) {
      const sources = Object.entries(qualityUrls).map(([quality, { url }]) => ({
        src: url,
        size: quality === 'original' ? '1080' : quality.split('p')[0],
        type: 'video/mp4',
      }));
      player.source = { type: 'video', sources };
    }

    playerRef.current = player;

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [qualityUrls]);

  return (
    <div className='video-player-plyr'>
      {/* Use the id attribute to match the CSS selector */}
      <video ref={videoElement} id="video-player" controls playsInline />
    </div>
  );
}

export default Player;