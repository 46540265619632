import React from 'react';

function GetStartedSection() {
  const sectionStyles = {
    background: 'orange',
    color: 'black',
    padding: '30px 0',
  };

  const titleStyles = {
    fontSize: '2rem',
    fontFamily: 'VT323, monospace', // 'VT323' font with monospace fallback
    textAlign: 'center',
  };

  return (
    <section className="get-started-section" style={sectionStyles}>
      <div className="container">
        <h2 className="section-title" style={titleStyles}>
          Join us in our journey of innovation and collaboration. Channel Linkup is now fully operational, bringing YouTubers and video editors together like never before!
        </h2>
      </div>
    </section>
  );
}

export default GetStartedSection;
