import React, { useState, useEffect } from 'react';
import icon from './icons/icon.png';
import VideoPlayer1 from './nPlayer';
import './VideoPlayer.css'; // Import your CSS file

const VideoPlayer = () => {
  const [contentData, setContentData] = useState([]);
  const [showMessage, setShowMessage] = useState(false); // State to manage the display of the message
  const [downloading, setDownloading] = useState(false); // State for tracking downloading status


  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');
  const hashParam = localStorage.getItem('hashParam');

  const fetchS3Content = async () => {
    try {
      const response = await fetch(`https://backend.channellinkup.com/api/s3-folder-content/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({ hashParam }),
      });

      if (response.ok) {
        const data = await response.json();
        setContentData(data.contentData);
      } else {
        console.error('Error fetching S3 folder content:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching S3 folder content:', error);
    }
  };

  useEffect(() => {
    fetchS3Content(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchS3Content(); // Fetch content every 30 minutes
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    return () => clearInterval(intervalId); // Clean up interval
  }, []);

  const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      case 'avi':
        return 'video/x-msvideo';
      case 'wmv':
        return 'video/x-ms-wmv';
      case 'mkv':
        return 'video/x-matroska';
      case 'flv':
        return 'video/x-flv';
      case 'webm':
        return 'video/webm';
      case 'mpeg':
        return 'video/mpeg';
      case '3gp':
        return 'video/3gpp';
      case 'm4v':
        return 'video/x-m4v';
      default:
        return 'application/octet-stream'; // fallback MIME type
    }
  };

  const getFileName = (filePath) => {
    return filePath.split('/').pop();
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadAll = async (contentData) => {
    try {
      setDownloading(true); // Set downloading to true
      const response = await fetch('https://backend.channellinkup.com/api/download-all-files/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({ contentData }),
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'files.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        setShowMessage(true); // Show message after download
        setTimeout(() => {
          setShowMessage(false); // Hide message after 10 seconds
        }, 10000);
      } else {
        console.error('Failed to fetch download link:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
    } finally {
      setDownloading(false); // Reset downloading to false
    }
  };

  return (
    <div className="video-player-container">
      <div className="raw-clips-container">
        <h2 className="raw-clips-heading">Raw Clips</h2>
      </div>

      <div className="content-items-container">
        {contentData.map((item, index) => (
          <div key={index} className="content-item-2">
            {item.key.toLowerCase().endsWith('.mp4') || item.key.toLowerCase().endsWith('.mov') || item.key.toLowerCase().endsWith('.avi') || item.key.toLowerCase().endsWith('.wmv') || item.key.toLowerCase().endsWith('.mkv') || item.key.toLowerCase().endsWith('.flv') || item.key.toLowerCase().endsWith('.webm') || item.key.toLowerCase().endsWith('.mpeg') || item.key.toLowerCase().endsWith('.3gp') || item.key.toLowerCase().endsWith('.m4v') ? (
                <VideoPlayer1 sourceUrl={item.url} />
              ) : item.key.toLowerCase().endsWith('.png') || item.key.toLowerCase().endsWith('.jpg') || item.key.toLowerCase().endsWith('.jpeg') || item.key.toLowerCase().endsWith('.gif') ? (
                <img src={item.url} alt={item.key} />
            ) : (
              <div className="file-icon"> {/* Add a CSS class for styling */}
                <img src={icon} alt="File Icon" />
              </div>
            )}
            <p className="file-name">{getFileName(item.key)}</p>
              <div className="file-actions">
                <button className="download-file-button" onClick={() => handleDownload(item.url, getFileName(item.key))}>Download</button>
              </div>
          </div>
        ))}
      </div>
      <div className="download-all-container">
        <button className="download-all-button" onClick={() => handleDownloadAll(contentData)} disabled={downloading}>
        {downloading ? 'Downloading...' : 'Download All'}
        </button>
        {showMessage && (
          <div className="download-message">
            <p style={{ color: 'rgb(162,96,70)' }}>Files are downloaded</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
