// VideoReview.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VideoReview.css';
import { useParams } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './VideoReview.css';

axios.defaults.baseURL = 'http://localhost:8000';

function VideoReview() {
  const { videoId } = useParams();
  const [videoDetails, setVideoDetails] = useState({});
  const [status, setDecision] = useState('');
  const [comments, setComments] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchVideoDetails();
  }, []);

  const fetchVideoDetails = async () => {
    try {
      const response = await axios.get(`/api/video_review/${videoId}/`);
      setVideoDetails(response.data);
    } catch (error) {
      console.error('Error fetching video details:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `/api/video_review/${videoId}/`,
        {
          status,
          comments,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setMessage('Decision submitted successfully');
    } catch (error) {
      console.error('Error submitting decision:', error);
    }
  };

  const handleDecisionClick = (decision) => {
    setDecision(decision);
  };

  const truncateDescription = (text, length) => {
    if (text) {
      if (text.length > length) {
        return text.slice(0, length) + '...';
      }
      return text;
    }
    return ''; // Return an empty string if the text is undefined
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  // Toggle the showFullDescription state when "Read More" is clicked
  const handleReadMoreClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getDescriptionDisplay = () => {
    if (videoDetails.description) {
      if (showFullDescription || videoDetails.description.length <= 60) {
        return videoDetails.description;
      }
      return videoDetails.description.slice(0, 60) + '...';
    }
    return '';
  };

  return (
    <div className="video-review text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h2 className="video-title">{videoDetails.title}</h2>
            
            <div className="video-player-wrapper">
              <video controls className="video-player" src={videoDetails.gdrive_url}></video>
            </div>
            <div className="video-description">
          <span className="video-description-label">Description: </span>
          {getDescriptionDisplay()}
          {videoDetails.description && videoDetails.description.length > 60 && (
            <span className="read-more" onClick={handleReadMoreClick}>
              {showFullDescription ? 'Read Less' : 'Read More'}
            </span>
              )}
            </div>
            <p className="tags">Tags: {videoDetails.tags}</p>
            <div className="decision-form">
              <div
                className={`decision ${status === 'approved' ? 'selected' : ''}`}
                onClick={() => handleDecisionClick('approved')}
              >
                Approve
              </div>
              <div
                className={`decision denied ${status === 'denied' ? 'selected' : ''}`}
                onClick={() => handleDecisionClick('denied')}
              >
                Deny
              </div>
            </div>
            <textarea
              className="form-control comment-input"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Comments"
            />
            <button type="submit" className="btn btn-primary submit-btn">Submit</button>
            <p className="message mt-3">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoReview;
