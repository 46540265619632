import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage/Homepage";
import VideoReview from "./components/VideoReview";
import SignUpPage from "./components/SignUp/SignUpPage";
import PrivateRoutes from "./PrivateRoutes"; // Import your PrivateRoute component
import UserDashboard from "./components/UserDashboard/UserDashboard"; // Import the UserDashboard component
import SignUpPageEditor from "./components/SignUpEditor/SignUpPageEditor";
import UserDashboardEditor from "./components/UserDashboardEditor/UserDashboardEditor";
import PrivateRoutesEditor from "./PrivateRoutesEditor";
import ContactPage from "./components/ContactForm/ContactPage";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the useAuth hook
import VideoUploadForm from "./components/test-upload";
import TermsOfServicePage from "./components/Legal/TermsOfServicePage";
import PrivacyPolicyPage from "./components/Legal/PrivacyPolicyPage";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import NamePage from "./components/Extras/NamePage";
import SettingsPage from "./components/SettingsPage/SettingsPage";
import SignInPage from "./components/SignIn/SignInPage";

function App() {
  const uniqueHash = localStorage.getItem("uniqueHash");
  const user = localStorage.getItem("user");
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              user === "youtuber" ? (
                <Navigate to={`/dashboard-${uniqueHash}`} replace />
              ) : (
                user === "editor" && <Navigate to={`/dashboard-editor-${uniqueHash}`} replace />
              )
            ) : (
              <Homepage />
            )
          }
        />
        <Route
          path="/signup-editor"
          element={
            isAuthenticated ? (
              user === "youtuber" ? (
                <Navigate to={`/signup-editor`} replace />
              ) : (
                user === "editor" && <Navigate to={`/dashboard-editor-${uniqueHash}`} replace />
              )
            ) : (
              <SignUpPageEditor />
            )
          }
        />
        <Route
          path="/signup"
          element={
            isAuthenticated ? (
              user === "youtuber" ? (
                <Navigate to={`/dashboard-${uniqueHash}`} replace />
              ) : (
                user === "editor" && <Navigate to={`/signup`} replace />
              )
            ) : (
              <SignUpPage />
            )
          }
        />

        <Route
          path="/login"
          element={
            isAuthenticated ? (
              user === "youtuber" ? (
                <Navigate to={`/dashboard-${uniqueHash}`} replace />
              ) : (
                user === "editor" && <Navigate to={`/signup`} replace />
              )
            ) : (
              <SignInPage />
            )
          }
        />

        <Route
          path="/settings"
          element={
            isAuthenticated && user === "youtuber" ? (
              <SettingsPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/linked" element={<NamePage />} />
        <Route path="/legal/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/contact" element={<ContactPage />} />
        {/*<Route path="/video-upload-youtube" element={<VideoUploadForm />} />
        <Route path="/video-review/:videoId" element={<VideoReview />} /> */}
        {/* <Route path="/signup" element={<SignUpPage />} /> */}
        {/* <Route path="/signup-editor" element={<SignUpPageEditor />} />
        <Route path="/message" element={<SignUpPageEditor />} /> */}
        <Route element={<PrivateRoutes />}>
          <Route element={<UserDashboard />} path={`/dashboard-${uniqueHash}`} />
        </Route>
        <Route element={<PrivateRoutesEditor />}>
          <Route element={<UserDashboardEditor />} path={`/dashboard-editor-${uniqueHash}`} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
