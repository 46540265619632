import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../AuthContext";
import jwt_decode from "jwt-decode";
import { SHA256 } from "crypto-js";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { gapi } from "gapi-script";


function SignUpForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm();

  const { login } = useAuth();
  const [verificationSent, setVerificationSent] = useState(false);
  const [emailForVerification, setEmailForVerification] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(true);
  const [showDivider, setShowDivider] = useState(true);
  const [showContinueWithEmail, setShowContinueWithEmail] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [email, setEmail] = useState("");


  function generateRandomNumber() {
    const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generates a random number between 100000 and 999999
    return randomNumber.toString();
  }

  function uniqueHashGen(randomNumber){
    const hash = SHA256(randomNumber).toString();
    return hash;
  }

  function handleCallbackResponse(response) {
    document.getElementById("signInDiv").hidden = true;
    const token = response.credential;
    fetch('https://backend.channellinkup.com/api/verify_jwt/', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.valid) {
          // Check if the user has an active subscription
          const subscriptionResponse = await fetch('https://backend.channellinkup.com/api/check-subscription/', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `JWT ${token}`,
              'X-Auth-Type': 'google',
            },
          });
  
          if (subscriptionResponse.ok) {
            localStorage.setItem('authType', 'google');
            const randomNumber = generateRandomNumber();
            const unique_hash = uniqueHashGen(randomNumber);
            localStorage.setItem('uniqueHash', unique_hash);
            localStorage.setItem('user', 'youtuber');
            const subscriptionData = await subscriptionResponse.json();
            if (subscriptionData.has_active_subscription) {
              // User has an active subscription, log them in and redirect to the dashboard
              login(token);
              window.location.href = `/dashboard-${unique_hash}`;
            } else {
              // User does not have an active subscription, redirect to the payment page
              window.location.href = `/payment?email=${subscriptionData.email}`;
            }
          } else {
            console.error("Error checking subscription status");
          }
        } else {
          console.error("JWT token verification failed");
        }
      })
      .catch((error) => {
        console.error("Error verifying JWT token:", error);
      });
  }

  useEffect(() => {
    const google = window.google;
    google.accounts.id.initialize({
      client_id: "891019605280-nbgvqes7lus3eukv01am2g766nj515o0.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    google.accounts.id.prompt();

    trigger("email");
  }, [trigger]);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    if (email) {
      try {
        setIsLoading(true);
        const response = await fetch(
          'https://backend.channellinkup.com/api/send_verification_code/',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (response.ok) {
          setEmailForVerification(email);
          setVerificationSent(true);
          document.getElementById("signInDiv").hidden = true;
          setShowCreateAccount(false);
          setShowDivider(false);
          setShowContinueWithEmail(false);
        }
      } catch (error) {
        console.error("Error sending verification code:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onVerifyCodeSubmit = async (data) => {
    try {
      setIsVerifying(true);
      const verificationResponse = await fetch(
        'https://backend.channellinkup.com/api/verify_verification_code/',
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //credentials: 'include',
          body: JSON.stringify({
            email: emailForVerification,
            verification_code: data.verificationCode,
          }),
        }
      );

      if (verificationResponse.ok) {
        const responseData = await verificationResponse.json();
        const token = responseData.token;

        // Check if the user has an active subscription
        const subscriptionResponse = await fetch('https://backend.channellinkup.com/api/check-subscription/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `JWT ${token}`,
            'X-Auth-Type': 'email',
          },
        });

        if (subscriptionResponse.ok) {
          localStorage.setItem('authType', 'email');
          const randomNumber = generateRandomNumber();
          const unique_hash = uniqueHashGen(randomNumber);
          localStorage.setItem('uniqueHash', unique_hash);
          localStorage.setItem('user', 'youtuber');
          const subscriptionData = await subscriptionResponse.json();
          if (subscriptionData.has_active_subscription) {
            // User has an active subscription, log them in and redirect to the dashboard
            login(token);
            window.location.href = `/dashboard-${unique_hash}`;
          } else {
              // User does not have an active subscription, redirect to the payment page
              window.location.href = `/payment?email=${subscriptionData.email}`;
          }
        } else {
            console.error("Error checking subscription status");
        }
      } else {
        const responseData = await verificationResponse.json();
        if (!responseData.matched_verification_code) {
          setVerificationError(
            <p className="verification-error">
              Code is invalid or expired.{" "}
              <span className="signup-again-link">
                <a href="/signup">Sign up again</a>
              </span>
            </p>
          );
        } else {
          console.error("Verification failed");
        }
      }
    } catch (error) {
      console.error("Error verifying verification code:", error);
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <div className="signup-form-container">
      <div className="signup-box">
        {showCreateAccount && (
          <h2 className="create-account-heading">Login</h2>
        )}
        <div className="google-login">
          <div className="centered-container">
            <div id="signInDiv"></div>
          </div>
          <script
            src="https://accounts.google.com/gsi/client"
            async
            defer
          ></script>
        </div>
        {showDivider && (
          <div className="line">
            <div className="line-divider"></div>
            <span className="line-text">Or continue with email</span>
            <div className="line-divider"></div>
          </div>
        )}

        {verificationSent ? (
          <div className="verification-box">
            <h2>Please check your email</h2>
            <p className="welcome-message">
              Welcome! We've sent a login code to {emailForVerification}.
            </p>
            <form
              className="verification-form"
              onSubmit={handleSubmit(onVerifyCodeSubmit)}
            >
              <input
                className="verification-input"
                type="text"
                placeholder="6-digit code"
                {...register("verificationCode", { required: true })}
                onChange={() => setVerificationError(null)}
              />
              {errors.verificationCode && (
                <span className="error-message">
                  Verification code is required
                </span>
              )}
              {verificationError && (
                <div className="verification-error">{verificationError}</div>
              )}
              <button
                className={`verify-button ${isVerifying ? "loading" : ""}`}
                type="submit"
                disabled={isVerifying}
              >
                {isVerifying ? (
                  <div className="loading-circle1"></div>
                ) : (
                  "Verify Code"
                )}
              </button>
            </form>
          </div>
        ) : (
          <div className="email-signup-box">
            <form className="signup-form" onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
              <input
                className="email-input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={onEmailChange}
              />
              {errors.email && (
                <span className="error-message">Email is required</span>
              )}
              <button
                className={`signup-button ${isLoading ? "loading" : ""} ${
                  email ? "" : "disabled"
                }`} // Add emailInput condition
                type="submit"
                disabled={!email || isLoading}
              >
                {isLoading ? <div className="loading-circle-with-one-animtion-only"></div> : "Login"}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUpForm;
