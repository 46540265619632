import React, { useState, useEffect } from 'react';
import relax from '../images/relax.gif';
import './ReviewAndPublish.css';
import VideoPlayer from './Player';

const ReviewAndPublish = ({ selectedProject }) => {
  const [videoInfo, setVideoInfo] = useState({});
  const [editingInstructions, setEditingInstructions] = useState('');
  const [publishStatus, setPublishStatus] = useState(null);
  const [videoUrls, setVideoUrls] = useState({});
  const [editorList, setEditorList] = useState([]);
  const [selectedEditor, setSelectedEditor] = useState('');
  const [isRequestingEdit, setIsRequestingEdit] = useState(false);


  useEffect(() => {
    getVideoInfo().then(() => {
      return fetchEditorList();
    }).then(() => {
      if (selectedEditor) {
        return fetchPreSignedUrls(selectedEditor);
      }
    });
    const interval = setInterval(() => {
      if (selectedEditor) {
        fetchPreSignedUrls(selectedEditor);
      }
    }, 1800000);
    return () => clearInterval(interval);
  }, [selectedEditor]);


  const getVideoInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const authType = localStorage.getItem('authType');

      const response = await fetch(`https://backend.channellinkup.com/api/get-video-info-publish/?project_name=${selectedProject}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching video info: ${response.status}`);
      }

      const data = await response.json();
      setVideoInfo(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEditorList = async () => {
    try {
      const token = localStorage.getItem('token');
      const authType = localStorage.getItem('authType');

      const response = await fetch(`https://backend.channellinkup.com/api/get-db-edited-clips-folder-list/?project_name=${selectedProject}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching editor list: ${response.status}`);
      }

      const data = await response.json();
      if (!selectedEditor) {
        setEditorList(data.editor_list);
      }
      if (data.editor_list.length > 0 && !selectedEditor) {
        setSelectedEditor(data.editor_list[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPreSignedUrls = async (selectedEditor) => {
    try {
      const token = localStorage.getItem('token');
      const authType = localStorage.getItem('authType');

      fetch(`https://backend.channellinkup.com/api/get-s3-edited-clips-folder-content/?project_name=${selectedProject}&editor_email=${selectedEditor}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error fetching pre-signed URL: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setVideoUrls(data.urls);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectEditor = (e) => {
    const selectedValue = e.target.value;
    setSelectedEditor(selectedValue);
    fetchPreSignedUrls(selectedValue);
  };


  const handleApproveAndPublish = async () => {
    try {
      const token = localStorage.getItem('token');
      const authType = localStorage.getItem('authType');

      const response = await fetch('https://backend.channellinkup.com/api/get-auth-url/', {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({ selectedEditor, selectedProject }),
      });

      if (response.ok) {
        const { auth_url } = await response.json();

        // Open authentication URL in a new tab
        window.open(auth_url, '_blank');
        
        setPublishStatus('Request sent. You will be notified as soon as the video is published to YouTube.');
        setTimeout(() => {
          setPublishStatus(null);
        }, 10000);

        setTimeout(() => {
        setPublishStatus('Video published successfully!');
      }, 30000); // 30 seconds delay for the message

      } else {
        const errorData = await response.json();
        console.error('Error uploading video:', errorData.error);
      }
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  };


  const handleRequestEdit = async () => {
    try {
      if (!editingInstructions.trim()) {
        console.log('Editing instructions are empty');
        setPublishStatus('Editing instructions are empty. Please enter some instructions.');
        setTimeout(() => {
          setPublishStatus(null);
        }, 5000);
        return;
      }

      setIsRequestingEdit(true); // Set loading state to true

      const token = localStorage.getItem('token');
      const authType = localStorage.getItem('authType');

      const response = await fetch('https://backend.channellinkup.com/api/send-editing-instructions-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({ selectedEditor, selectedProject, editingInstructions }),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setEditingInstructions(''); // Clear the editing instructions box
        setPublishStatus('Email sent successfully'); // Show a message that email is sent
        setTimeout(() => {
          setPublishStatus(null); // Remove the message after 5 seconds
        }, 10000);
      } else {
        const errorData = await response.json();
        console.error('Error sending email:', errorData.error);
        setPublishStatus('Error sending email'); // Show a message that email is sent
        setTimeout(() => {
          setPublishStatus(null); // Remove the message after 5 seconds
        }, 10000);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
    finally{
      setIsRequestingEdit(false);
    }
  };
  

  return (
    <div className="review-and-publish">
      <h2>Review and Publish</h2>
      <div>
      {videoInfo.editing === 0 && (
          <div>
            <img src={relax} alt="Relax GIF" style={{ display: 'block', margin: '20px auto' }} preload="auto"/>
          </div>
        )}
        {videoInfo.editing === -1 && (
          <div>
            <p>The video editor is working on editing the video.</p>
            <img src={relax} alt="Relax GIF" style={{ display: 'block', margin: '20px auto' }} preload="auto"/>
          </div>
        )}

        {videoInfo.editing === 1 && (
          <div>
            <h2>{videoInfo.title}</h2>
            <p>Description: {videoInfo.description}</p>
            <p>Tags: {videoInfo.tags}</p>

            <div className="editor-dropdown">
              <label>Video Editor:</label>
              <select value={selectedEditor} onChange={handleSelectEditor}>
                {editorList.map((editor) => (
                  <option key={editor} value={editor}>{editor}</option>
                ))}
              </select>
            </div>

            <VideoPlayer qualityUrls={videoUrls} />
            


            <div className="button-container">
              <div className="edit-container">
                <input
                  type="text"
                  placeholder="Enter editing instructions"
                  value={editingInstructions}
                  onChange={(e) => setEditingInstructions(e.target.value)}
                />
                <button
                  className={`request-edit-btn ${isRequestingEdit ? 'loading' : ''}`}
                  onClick={handleRequestEdit}>
                  {isRequestingEdit ? <div className="loading-circle1"></div> : 'Request Edit'}
                </button>
              </div>
              <button className="approve-publish-btn" onClick={handleApproveAndPublish}>Approve and Publish</button>
            </div>
            {publishStatus && (
              <div className="publish-status">
                <p>{publishStatus}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewAndPublish;