import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import LandingPage from './LandingPage';
import { useAuth } from "../../AuthContext";
import "./UserDashboardEditor.css";

function UserDashboardEditor() {
  const [activeTab, setActiveTab] = useState('video-content');
  const { login } = useAuth();
  const { logout } = useAuth();

  const checkTokenExpiration = () => {
    let token = localStorage.getItem('token');
    let authType = localStorage.getItem('authType');
    fetch('https://backend.channellinkup.com/api/check-token-expiration-editor/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${token}`,
        'X-Auth-Type': authType,
      },
    })
    .then(response => {
      if (!response.ok) {
        logout();
        console.log('User token is expired. Logging out...');
      }
    })
    .catch(error => {
      console.error('Error checking token expiration:', error);
    });
};


  useEffect(() => {
    checkTokenExpiration();
    const intervalId = setInterval(checkTokenExpiration, 30000); // Check every 30 seconds
    return () => clearInterval(intervalId);
  }, [logout]);


  const refreshToken = async () => {
    let authType = localStorage.getItem('authType');
    let token = localStorage.getItem('token');
    try {
      const response = await fetch('https://backend.channellinkup.com/api/refresh-token-editor/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });
      const data = await response.json();
      if (data.token) {
        login(data.token);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  useEffect(() => {
    const refreshTokenInterval = 900000; //900000= 15 minutes in milliseconds
  
    const refreshTokenTimer = setInterval(() => {
      refreshToken();
    }, refreshTokenInterval);
  
    let token = localStorage.getItem('token');
    if (token) {
      refreshToken();
    }
  
    return () => {
      clearInterval(refreshTokenTimer);
    };
  }, []); // Empty dependency array to run only once when the component mounts

  return (
    <div className="user-dashboard">
      <Header />
      <div className="dashboard-content">
        <Sidebar setActiveTab={setActiveTab} />
        <LandingPage
          activeTab={activeTab}
        />
      </div>
    </div>
  );
}

export default UserDashboardEditor;
