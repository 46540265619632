import React from 'react';
import VideoUpload from './VideoUpload';
import VideoPlayer from './VideoPlayer';

function LandingPage({ activeTab }) {
  return (
    <div className="landing-page">
      {activeTab === 'video-content' && (
        <div className="content">
          <VideoPlayer />
        </div>
      )}
      {activeTab === 'upload-video' && (
        <div className="content">
          <VideoUpload />
        </div>
      )}
    </div>
  );
}

export default LandingPage;