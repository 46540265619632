import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container1">
      <header className="header">
        <h1>Terms of Service</h1>
        <p>Last Updated: April 21, 2024</p>
      </header>

      <section className="section">
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using Channel Linkup, you agree to these Terms of Service, our Privacy Policy, and any additional terms that may apply. If you do not agree with any part of these terms, please do not use our platform.
        </p>
      </section>

      <section className="section">
        <h2>2. Registration and Account Security</h2>
        <p>
          2.1. You may sign up using Google or by providing your email address. You are responsible for maintaining the security of your account, and you must promptly notify us of any unauthorized use or security breaches.
        </p>
        <p>
          2.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </p>
      </section>

      <section className="section">
        <h2>3. Collaborative Process</h2>
        <p>3.1. Youtubers can upload raw video clips to the platform.</p>
        <p>3.2. Video Editors can be added by Youtubers to collaborate on the editing process.</p>
        <p>3.3. Final edited videos can be uploaded by Video Editors.</p>
        <p>3.4. Youtubers can access the final edited video and decide whether to publish it to their YouTube channel.</p>
      </section>

      <section className="section">
        <h2>4. Data Usage</h2>
        <p>4.1. We use Google Cloud Platform (GCP) and Amazon Web Services (AWS) to store and manage user data securely.</p>
        <p>4.2. We utilize the YouTube Data API v3 for uploading final videos to Youtuber's YouTube channels.</p>
        <p>4.3. YouTube API Services: By using Channel Linkup's API clients, you agree to be bound by the YouTube API Services and the YouTube Terms of Service available at <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">https://www.youtube.com/t/terms</a>.</p>
      </section>

      <section className="section">
        <h2>5. User Conduct</h2>
        <p>5.1. Users must comply with all applicable laws and regulations.</p>
        <p>5.2. Users are responsible for the content they upload, and it must not violate the rights of others or be illegal or inappropriate.</p>
        <p>5.3. Third-Party Content: Channel Linkup does not allow third parties to serve content, including advertisements, on our platform.</p>
      </section>

      <section className="section">
        <h2>6. Intellectual Property</h2>
        <p>6.1. Users retain ownership of their uploaded content.</p>
        <p>6.2. By uploading content, users grant Channel Linkup a non-exclusive, worldwide, royalty-free license to use, display, and distribute the content on the platform.</p>
      </section>

      <section className="section">
        <h2>7. Cancellation and Refund Policy</h2>
        <p>7.1. Users can cancel their subscription to Channel Linkup at any time.</p>
        <p>7.2. No Refunds: There will be no refund of payments made for using Channel Linkup.</p>
        <p>7.3. Users can cancel their subscription by going to their account settings and following the provided procedures.</p>
      </section>

      <section className="section">
        <h2>8. Termination</h2>
        <p>8.1. Channel Linkup reserves the right to terminate or suspend any user account at its discretion.</p>
        <p>8.2. Users may terminate their account at any time by following the provided procedures.</p>
        <p>8.3. Revoking Access: Users have the right to revoke Channel Linkup's access to their data via the Google security settings page at <a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noopener noreferrer">https://security.google.com/settings/security/permissions</a>.</p>
      </section>

      <section className="section">
        <h2>9. Limitation of Liability</h2>
        <p>9.1. Channel Linkup is not liable for any damages resulting from the use or inability to use the platform.</p>
      </section>

      <section className="section">
        <h2>10. Changes to Terms</h2>
        <p>10.1. Channel Linkup may update these terms at any time. Users will be notified of changes, and continued use of the platform after changes constitutes acceptance.</p>
      </section>

      <section className="section">
        <h2>11. Contact Information</h2>
        <p>
          For questions or concerns regarding these terms, please contact us at <a href="mailto:channellinkup@gmail.com">channellinkup@gmail.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
