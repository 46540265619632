import React, { useState, useEffect } from 'react';
import './ProjectDetails.css'; // Import your CSS file

function ProjectDetails({ onProjectCreated, activeTab, setActiveTab }) {
  const [projectName, setProjectName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [projectExists, setProjectExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [fileInputKey, setFileInputKey] = useState(0); // New state for input file key
  const [errorMessage, setErrorMessage] = useState('');

  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');

  useEffect(() => {
    checkProjectExistence();
  }, [projectName]);

  const handleFileSelect = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };

  const removeSelectedFile = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const checkProjectExistence = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${token}`,
        'X-Auth-Type': authType,
      },
    };

    fetch(`https://backend.channellinkup.com/api/check-project-existence/?projectName=${projectName}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setProjectExists(data.exists);
      })
      .catch((error) => {
        console.error('Error checking project existence', error);
      });
  };

  const handleProjectCreate = () => {
    if (!projectName.trim()) {
      console.log('Please fill in the project name.');
      setErrorMessage('Please fill in the project name.');
      return;
    }
  
    if (projectExists || loading) {
      console.log('Project name already exists or loading');
      setErrorMessage('Project name already exists or loading');
      return;
    }
  
    setLoading(true);
  
    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append('files', file));
  
    formData.append('projectName', projectName);
  
    fetch('https://backend.channellinkup.com/api/upload-content/', {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`,
        'X-Auth-Type': authType,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccessMessage('Project created successfully');
        onProjectCreated(projectName);
  
        setTimeout(() => {
          setProjectName('');
          setSelectedFiles([]);
          setSuccessMessage('');
          setFileInputKey((prevKey) => prevKey + 1); // Increment key to clear file input
          setActiveTab('editor');
        }, 5000);
      })
      .catch((error) => {
        console.error('Project creation and upload error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="project-details-section">
      <h2>Create a Project</h2>
      <label htmlFor="projectName">Project Name:</label>
      <input
        type="text"
        id="projectName"
        value={projectName}
        onChange={(e) => {
          setProjectName(e.target.value);
          setErrorMessage(''); // Clear error message when input changes
        }}
      />

      {projectExists && (
        <p className="project-exists-message">Project name already exists</p>
      )}

      <input
        key={fileInputKey}
        type="file"
        multiple
        onChange={handleFileSelect}
      />
      {selectedFiles.length > 0 && (
        <div className="selected-files">
          <p>Selected files:</p>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                {file.name}
                <button
                  className="remove-file"
                  onClick={() => removeSelectedFile(index)}
                >
                  ✖
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <button onClick={handleProjectCreate} disabled={projectExists || loading}>
        {loading ? 'Creating Project...' : 'Create Project'}
      </button>

      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}


      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
        </div>
      )}
    </div>
  );
}

export default ProjectDetails;
