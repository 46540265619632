import React from 'react';

function FeaturesSection() {
  return (
    <section className="features-section">
      <div className="container">
        <h2 className="section-title">Why Choose Channel Linkup?</h2>
        <div className="row">
          <div className="col-md-4 feature-item">
            <div className="feature-icon">
              <i className="fas fa-clock"></i>
            </div>
            <h3 className="feature-title">Streamlined Collaboration</h3>
            <p className="feature-description">
              Channel Linkup simplifies content creation and editing for YouTubers and video editors. It offers a single platform for project initiation, video uploads, and editing instructions, fostering efficient collaboration.
            </p>
          </div>
          <div className="col-md-4 feature-item">
            <div className="feature-icon">
              <i className="fas fa-cloud-upload-alt"></i>
            </div>
            <h3 className="feature-title">Efficient Upload Process</h3>
            <p className="feature-description">
              Our innovative technology minimizes internet usage during video uploads. YouTubers can upload videos from anywhere, enhancing efficiency and reducing the environmental footprint.
            </p>
          </div>
          <div className="col-md-4 feature-item">
            <div className="feature-icon">
              <i className="fas fa-leaf"></i>
            </div>
            <h3 className="feature-title">Accessibility and Sustainability</h3>
            <p className="feature-description">
              We're committed to making content creation eco-friendly and accessible. Channel Linkup promotes sustainability in the digital content creation industry.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
