import React from "react";
import Header from "./Header";
import SignUpForm from "./SignUpForm";
import "./SignUpPage.css"; // Import or define your CSS file for SignUpPage styles

function SignUpPage() {
  return (
    <div className="signup-page">
      <Header />
      <div className="center-content">
        <SignUpForm/>
      </div>
    </div>
  );
}

export default SignUpPage;
