import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import LandingPage from './LandingPage';
import EditorInfoForm from './EditorInfoForm';
import FolderUpload from './FolderUpload';
import ProjectDetails from './ProjectDetails';
import VideoInfoInstructions from './VideoInfoInstructions';
import ReviewAndPublish from './ReviewAndPublish';
import { useAuth } from "../../AuthContext";
import "./UserDashboard.css";

function UserDashboard() {
  const [activeTab, setActiveTab] = useState('projects');
  const [selectedProject, setSelectedProject] = useState('');
  const [projectList, setProjectList] = useState([]);
  const { login } = useAuth();
  const { logout } = useAuth();
  const [refreshInterval, setRefreshInterval] = useState(null);

  
  const handleProjectChange = (project) => {
    setSelectedProject(project);
  };

  const handleProjectCreated = (projectName) => {
    setProjectList((prevProjects) => [...prevProjects, projectName]);
  };

  const checkSubscription = () => {
    let token = localStorage.getItem('token');
    let authType = localStorage.getItem('authType');
    fetch('https://backend.channellinkup.com/api/check-subscription/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${token}`,
        'X-Auth-Type': authType,
      },
    })
    .then(response => response.json())
    .then(data => {
      if (!data.has_active_subscription) {
        logout();
        console.log('User is not subscribed. Logging out...');
      }
    })
    .catch(error => {
      console.error('Error checking subscription:', error);
    });
  };

  useEffect(() => {
    checkSubscription();
    const intervalId = setInterval(checkSubscription, 30000); // Check every 30 seconds
    return () => clearInterval(intervalId);
  }, [logout]);

  const refreshToken = async () => {
    let authType = localStorage.getItem('authType');
    let token = localStorage.getItem('token');
    try {
      const response = await fetch('https://backend.channellinkup.com/api/refresh-token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });
      const data = await response.json();
      if (data.token) {
        login(data.token);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  useEffect(() => {
    const refreshTokenInterval = 900000; //900000= 15 minutes in milliseconds
  
    const refreshTokenTimer = setInterval(() => {
      refreshToken();
    }, refreshTokenInterval);
  
    let token = localStorage.getItem('token');
    if (token) {
      refreshToken();
    }
  
    return () => {
      clearInterval(refreshTokenTimer);
    };
  }, []); // Empty dependency array to run only once when the component mounts
  

  return (
    <div className="user-dashboard">
      <Header onProjectChange={handleProjectChange} projectList={projectList} />
      <div className="dashboard-content">
        <Sidebar setActiveTab={setActiveTab} />
        <LandingPage
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          selectedProject={selectedProject}
          onProjectCreated={handleProjectCreated} // Pass the prop here
        />
      </div>
    </div>
  );
}

export default UserDashboard;
