import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
          <a className="navbar-brand" href="/">
            Channel Linkup
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        <div
            className={`collapse navbar-collapse ${showMobileMenu ? "show" : ""}`}>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                  <a className="nav-link retro-link" href="/contact">
                    Contact Us
                  </a>
                </li>
            </ul>
        </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
