import React, { useState, useEffect } from 'react';
import { useAuth } from "../../AuthContext";
import './Settings.css';

const Settings = () => {
  const [username, setUsername] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const { logout } = useAuth();
  const [isLoadingCancelSubscription, setIsLoadingCancelSubscription] = useState(false);
  const [isLoadingDeleteAccount, setIsLoadingDeleteAccount] = useState(false);


  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');

  useEffect(() => {
    // Fetch user data, projects, and subscription details from the server
    fetchUserData();
    fetchProjects();
    fetchSubscriptionDetails();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://backend.channellinkup.com/api/get-user-data/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType,
        },
      });
      const data = await response.json();
      setUsername(data.username || '');
      setUserEmail(data.email);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`https://backend.channellinkup.com/api/get-projects/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
      });
      const data = await response.json();
      setProjects(data.projects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchSubscriptionDetails = async () => {
    try {
      const response = await fetch('https://backend.channellinkup.com/api/get-subscription-details/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
      });
      const data = await response.json();
      setSubscriptionDetails(data);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSaveUsername = async () => {
    try {
      if (typeof username !== 'string') {
        console.error('Username is not a string');
        return;
      }

      await fetch('https://backend.channellinkup.com/api/update-username/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
        body: JSON.stringify({ username: username }), // Assuming username is a string
      });
      // Show a success message or perform any additional actions
    } catch (error) {
      console.error('Error updating username:', error);
    }
  };


  const handleDeleteProject = async () => {
    try {
      const encodedProjectName = encodeURIComponent(selectedProject);
      await fetch(`https://backend.channellinkup.com/api/delete-project/?project_name=${encodedProjectName}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
      });
      fetchProjects(); // Refresh the projects list
      setSelectedProject(''); // Reset the selected project
      setShowDeleteSuccess(true); // Show the success message
      setTimeout(() => {
        setShowDeleteSuccess(false); // Hide the success message after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };


  const handleCancelSubscription = async () => {
    setShowCancelConfirmation(true);
  };

  const confirmCancelSubscription = async () => {
    try {
      setIsLoadingCancelSubscription(true); // Set loading state to true

      const response = await fetch('https://backend.channellinkup.com/api/cancel-subscription/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
      });

      if (response.ok) {
        // Show a success message
        console.log('Subscription canceled successfully');

        // Log out the user
        logout();

      } else {
        // Handle other responses (e.g., error messages from the server)
        console.error('Error canceling subscription:', response.statusText);
      }

      // Hide the cancellation confirmation modal
      setShowCancelConfirmation(false);
    } catch (error) {
      console.error('Error canceling subscription:', error);
    } finally {
      setIsLoadingCancelSubscription(false); // Set loading state back to false
    }
  };


  const handleDeleteAccount = async () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteAccount = async () => {
    try {
      setIsLoadingDeleteAccount(true); // Set loading state to true

      const response = await fetch('https://backend.channellinkup.com/api/delete-account/', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
      });

      if (response.ok) {
        // Show success message
        console.log('Account deleted successfully');

        // Log out the user
        logout();
        // Redirect to the login page or perform any additional actions
        setShowDeleteConfirmation(false);
      } else {
        // Handle other responses (e.g., error messages from the server)
        const errorMessage = await response.text();
        console.error('Error deleting account:', errorMessage);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }finally {
      setIsLoadingDeleteAccount(false); // Set loading state back to false
    }
  };


  return (
    <div className="settings-container">
      <h2> Account Settings</h2>

      <div className="setting-section">
        <h3>User Information</h3>
        <div className="setting-item">
          <label htmlFor="username" className="label">Name:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
          />
          <button className='save-username-btn' onClick={handleSaveUsername}>Save</button>
        </div>
        <div className="setting-item">
          <label htmlFor="email" className="label">Email:</label>
          <input type="email" id="email" value={userEmail} disabled />
        </div>
      </div>

      <div className="setting-item">
        <label htmlFor="project-select" className="label">Delete Project:</label>
        <select
          id="project-select"
          value={selectedProject}
          onChange={(e) => setSelectedProject(e.target.value)}
        >
          <option value="">-- Select a project --</option>
          {projects.map((projectName) => (
            <option key={projectName} value={projectName}>
              {projectName}
            </option>
          ))}
        </select>
        <button className='delete-project-btn'
          onClick={handleDeleteProject}
          disabled={!selectedProject}
        >
          Delete
        </button>
      </div>

      {showDeleteSuccess && (
        <div className="success-message">
          Project deleted successfully.
        </div>
      )}

      {subscriptionDetails && (
        <div className="setting-section">
          <h3>Subscription</h3>
          <div className="setting-item-1">
            <p>Subscription is valid until {new Date(subscriptionDetails.subscription_end_date).toLocaleString()}. You will be charged on {new Date(subscriptionDetails.subscription_end_date).toLocaleString()}.</p>
          </div>
          <div className="setting-item">
            <button className='cancel-subscription-btn' onClick={handleCancelSubscription}>Cancel Subscription</button>
          </div>
        </div>
      )}


      <div className="setting-section">
        <h3>Delete Account</h3>
        <div className="setting-item-1">
          <p>This action will result in the permanent deletion of your account and the automatic cancellation of your subscription.</p>
        </div>
        <div className="setting-item">
          <button className='delete-account-btn' onClick={handleDeleteAccount}>Delete Account</button>
        </div>
      </div>

      {showCancelConfirmation && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <h3>Cancel Subscription</h3>
            <p>Are you sure you want to cancel your subscription?</p>
            <div className="modal-actions">
              <button onClick={confirmCancelSubscription} disabled={isLoadingCancelSubscription}>
                {isLoadingCancelSubscription ? (
                  <svg className="loading-spinner" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="8" fill="none" stroke="currentColor" strokeWidth="4" />
                  </svg>
                ) : 'Yes'}
              </button>
              <button onClick={() => setShowCancelConfirmation(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirmation && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <h3>Delete Account</h3>
            <p>Are you sure you want to delete your account?</p>
            <div className="modal-actions">
              <button className='yes-button' onClick={confirmDeleteAccount} disabled={isLoadingDeleteAccount}>
                {isLoadingDeleteAccount ? (
                  <svg className="loading-spinner" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="8" fill="none" stroke="currentColor" strokeWidth="4" />
                </svg>                
                ) : 'Yes'}
              </button>
              <button className='no-button' onClick={() => setShowDeleteConfirmation(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
