import React from "react";
import Header from "./Header";
import SignUpForm from "./SignInForm";
import "./SignInPage.css"; // Import or define your CSS file for SignUpPage styles

function SignInPage() {
  return (
    <div className="signup-page">
      <Header />
      <div className="center-content">
        <SignUpForm/>
      </div>
    </div>
  );
}

export default SignInPage;
