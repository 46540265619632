import React, { useState, useEffect } from 'react';

const ThankYouPage = () => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      window.close();
    }, 10000);

    return () => clearInterval(countdownInterval);
  }, []);

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
      <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
        <h2>Thank you! You may close the window now.</h2>
        <p style={{ fontSize: '24px', fontWeight: 'bold', marginTop: '20px', color: '#555' }}>
          Closing in {countdown} seconds...
        </p>
      </div>
    </div>
  );
}

export default ThankYouPage;