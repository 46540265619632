import React from 'react';

function TestimonialsSection() {
  const textStyles = {
    fontSize: '2rem', // Adjust the font size as needed
    //fontWeight: 'bold',
    fontFamily: 'VT323, monospace',
    textAlign: 'center', // Center each line of text
  };

  return (
    <section className="testimonials-section" style={{ background: 'orange', color: 'black' }}>
      <div className="container">
        <p style={textStyles}>
          Channel Linkup is now live!
        </p>
        <p style={textStyles}>
          Explore our platform and start collaborating today.
        </p>
      </div>
    </section>
  );
}

export default TestimonialsSection;
