import React from "react";
import Header from "./Header";
import SignUpFormEditor from "./SignUpFormEditor";
import "./SignUpPageEditor.css"; // Import or define your CSS file for SignUpPage styles

function SignUpPageEditor() {
  return (
    <div className="signup-page">
      <Header />
      <div className="center-content">
        <SignUpFormEditor />
      </div>
    </div>
  );
}

export default SignUpPageEditor;
