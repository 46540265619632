import React, { useState, useEffect } from 'react';
import emailValidator from 'email-validator'; // Import the email-validator library
import './EditorInfoForm.css';

function EditorInfoForm({ selectedProject }) {
  const [editorName, setEditorName] = useState('');
  const [editorEmail, setEditorEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editorsList, setEditorsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');

  useEffect(() => {
    // Fetch existing editor info from the backend when the component mounts
    fetchEditorsList();
  }, []);

  const fetchEditorsList = async () => {
    try {
      const response = await fetch(`https://backend.channellinkup.com/api/get-editor-list/?project_name=${selectedProject}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setEditorsList(data.editors);
      } else {
        console.error('Error fetching editors list');
        // Handle error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('Error fetching editors list:', error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleSubmitEditorInfo = async (e) => {
    e.preventDefault();

    // Form validation
    if (!editorName || !editorEmail) {
      setError('Please fill out all fields.');
      return;
    }

    if (!emailValidator.validate(editorEmail)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Set loading state to true
    setLoading(true);

    // Call an API endpoint to send editor info to the backend
    try {
      const response = await fetch('https://backend.channellinkup.com/api/give-editor-access/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({
          editorName,
          editorEmail,
          selectedProject,
        }),
      });

      if (response.ok) {
        console.log('Editor added');
        setError('');
        setSuccessMessage('Editor added');

        // Refresh the existing editor info after granting access
        fetchEditorsList();

        // Clear fields after granting access
        setEditorName('');
        setEditorEmail('');

        // Handle success, e.g., show a success message to the user
      } else {
        console.error('Error adding editor');
        // Handle error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('Error sending editor info:', error);
      // Handle error, e.g., show an error message to the user
    } finally {
      // Set loading state back to false after the request is completed
      setLoading(false);

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
  };

  const handleRemoveEditor = async (editorEmail) => {
    try {
      const response = await fetch('https://backend.channellinkup.com/api/remove-editor-access/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({
          editorEmail,
          selectedProject,
        }),
      });

      if (response.ok) {
        console.log('Editor removed');
        setError('');
        setSuccessMessage('Editor removed');

        // Refresh the existing editor info after removing access
        fetchEditorsList();
      } else {
        console.error('Error removing editor');
      }
    } catch (error) {
      console.error('Error removing editor:', error);
    } finally {
      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
  };


  return (
    <div className="editor-info-form-section">
      <div className="editor-info">
        {editorsList.length > 0 && (
          <div>
            {/* Display the list of editors */}
            <h2>Video Editor Information</h2>
            <ul>
              {editorsList.map((editor, index) => (
                <li key={index}>
                  {editor.name} - {editor.email}
                  <button className="remove-button" onClick={() => handleRemoveEditor(editor.email)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <h2>Add Editor</h2>
        <form onSubmit={handleSubmitEditorInfo}>
          <label htmlFor="editorName">Editor's Name:</label>
          <input
            type="text"
            id="editorName"
            value={editorName}
            onChange={(e) => setEditorName(e.target.value)}
          />

          <label htmlFor="editorEmail">Editor's Email:</label>
          <input
            type="email"
            id="editorEmail"
            value={editorEmail}
            onChange={(e) => setEditorEmail(e.target.value)}
          />
          {error && <p className="error-message">{error}</p>}

          <button type="submit" disabled={loading}>
            {loading ? 'Granting Access...' : 'Grant Editor Access'}
          </button>

          {successMessage && <p className="success-message">{successMessage}</p>}
        </form>
      </div>
    </div>
  );
}

export default EditorInfoForm;
