import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

function Header() {

  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const { user, logout } = useAuth();
  const userToken = user.token;
  const userData = jwt_decode(userToken);

  useEffect(() => {
    const handleOutsideClick = (event) => {

      if (
        accountDropdownOpen &&
        !event.target.closest('.account-dropdown')
      ) {
        setAccountDropdownOpen(false);
      }
    };

    window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [accountDropdownOpen]);


  const toggleAccountDropdown = () => {
    setAccountDropdownOpen(!accountDropdownOpen);
  };

  return (
    <header className="header1">
      <Link className="navbar-brand1" to="/">
        Channel Linkup
      </Link>
      <nav className="account-nav">
        <div className="account-dropdown">
          <button onClick={toggleAccountDropdown}>
            My Account
          </button>
          {accountDropdownOpen && (
            <div className="dropdown-content">
              <p className="email">{userData.email}</p>
              <Link to="/">
                <button>Dashboard</button>
              </Link>
              <Link to="/contact">
                <button>Contact Us</button>
              </Link>
              <button onClick={logout}>Logout</button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
