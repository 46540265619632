import React, { useState, useEffect } from 'react';
import './VideoUpload.css';
import VideoPlayer from './Player'; // Assuming you have a VideoPlayer component

function VideoUpload() {
  const [videoFile, setVideoFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoUrls, setVideoUrls] = useState({});

  useEffect(() => {
    fetchPreSignedUrls();
  }, []);

  const fetchPreSignedUrls = async () => {
    try {
      const token = localStorage.getItem('token');
      const authType = localStorage.getItem('authType');
      const hashParam = localStorage.getItem('hashParam');

      const response = await fetch(`https://backend.channellinkup.com/api/get-s3-edited-clips-folder-content-editor/?hashParam=${hashParam}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching pre-signed URLs: ${response.status}`);
      }

      const data = await response.json();
      setVideoUrls(data.urls);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const UPLOAD_URL = 'https://backend.channellinkup.com/api/create-folder-and-upload-editor/';
    const SET_EDITING_STATUS_URL = 'https://backend.channellinkup.com/api/set-editing-status/';
    const token = localStorage.getItem('token');
    const authType = localStorage.getItem('authType');
    const hashParam = localStorage.getItem('hashParam');
    const formData = new FormData();
    formData.append('videoFile', videoFile);
    formData.append('hashParam', hashParam);

    try {
      setUploadStatus('Uploading...');

      // Use fetch for uploading the video
      const response = await fetch(UPLOAD_URL, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType,
        },
        body: formData,
      });

      if (response.ok) {
        // Send a request to set editing status to 1 using fetch
        await fetch(SET_EDITING_STATUS_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
            'X-Auth-Type': authType,
          },
          body: JSON.stringify({ hashParam }),
        });

        setUploadStatus(`The video "${videoFile.name}" has been uploaded and is awaiting approval from the owner. Thank you!\n\nIf the video file does not appear on the screen, please refresh the page.`);
        setVideoFile(null);
      } else {
        setUploadStatus('Error uploading video');
        console.error('Error uploading video:', response.status, response.statusText);
      }
    } catch (error) {
      setUploadStatus('Error uploading video');
      console.error('Error uploading video:', error);
    }
  };

  return (
    <div className="upload-container">
      <h2 className="upload-title">Upload the Video</h2>
      <form className="upload-form" encType="multipart/form-data">
        <input type="file" className="file-input" onChange={handleFileChange} id="file-input" />
        <label htmlFor="file-input" className="file-label">
          <span className="file-input-text">Select Video File</span>
        </label>
        {videoFile && <span className="selected-file-text"> Selected file: {videoFile.name} </span>}
        <button type="button" className="upload-button" onClick={handleSubmit}>
          Upload Video
        </button>
      </form>
      {uploadProgress > 0 && uploadProgress < 100 && (
        <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${uploadProgress}%` }} />
        </div>
      )}
      {uploadStatus && (
        <p className={`upload-status ${uploadStatus.includes('uploaded') ? 'success' : 'error'}`}>{uploadStatus}</p>
      )}

{Object.keys(videoUrls).length > 0 && (
    <h3 className="uploaded-video-title">Video</h3>
  )}

  {/* Video Player Container */}
  <div className="video-player-container1">
    {Object.keys(videoUrls).length > 0 && <VideoPlayer qualityUrls={videoUrls} />}
  </div>

    </div>
  );
}

export default VideoUpload;