import React from "react";

function Header() {
  return (
    <header className="header">
        <div className="container">
          <a className="navbar-brand header-title" href="/">
            Channel Linkup
          </a>
        </div>
    </header>
  );
}

export default Header;
