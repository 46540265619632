import React from "react";
import Header from "./Header";
import WrappedPaymentPage from "./Payment"
import "./Payment.css"; // Import or define your CSS file for SignUpPage styles

function PaymentPage() {
  return (
    <div className="payment-page">
      <Header />
      <div className="center-content">
        <WrappedPaymentPage/>
      </div>
    </div>
  );
}

export default PaymentPage;
