import React from 'react';
import FolderUpload from './FolderUpload';
import EditorInfoForm from './EditorInfoForm';
import ProjectDetails from './ProjectDetails'; // Import the new component
import VideoInfoInstructions from './VideoInfoInstructions';
import ReviewAndPublish from './ReviewAndPublish';


function LandingPage({ activeTab, setActiveTab, selectedProject, onProjectCreated }) {
  return (
    <div className="landing-page">
      {activeTab === 'projects' && (
        <div className="content">
          <ProjectDetails
            selectedProject={selectedProject}
            onProjectCreated={onProjectCreated} // Pass the prop to ProjectDetails
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      )}
      {activeTab === 'upload' && (
        <div className="content">
          <FolderUpload selectedProject={selectedProject} />
        </div>
      )}
      {activeTab === 'editor' && (
        <div className="content">
          <EditorInfoForm selectedProject={selectedProject} />
        </div>
      )}
      {activeTab === 'video' && (
        <div className="content">
          <VideoInfoInstructions selectedProject={selectedProject} />
        </div>
      )}
      {activeTab === 'youtube' && (
        <div className="content">
          <ReviewAndPublish selectedProject={selectedProject} />
        </div>
      )}
    </div>
  );
}

export default LandingPage;
