// ContactPage.js
import React from 'react';
import Header from './Header';
import Footer from '../Homepage/Footer';
import ContactForm from './ContactForm';
import './ContactPage.css';

function ContactPage() {
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  return (
    <div className="contact-page">
      <Header />
      <div className="contact-container">
        <div className="contact-info">
          {formSubmitted ? null : ( // Check if the form is submitted, and only show the message if it's not
            <div>
              <h2>Contact Us</h2>
              <p>If you have any queries, feel free to contact us.</p>
            </div>
          )}
        </div>
        <div className="contact-form">
          <ContactForm onFormSubmit={() => setFormSubmitted(true)} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
