import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import './nPlayer.css';

const NPlayer = ({ sourceUrl }) => {
  const videoElement = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Clean up the previous instance of Plyr
    if (playerRef.current) {
      playerRef.current.destroy();
    }

    // Initialize Plyr with the video element using a CSS selector
    const player = new Plyr('#video-player', {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen'],
      autoplay: false,
      seekTime: 10,
      volume: 1,
      muted: false,
      clickToPlay: true,
      disableContextMenu: true,
      hideControls: true,
      resetOnEnd: false,
      keyboard: {
        focused: true,
        global: false,
      },
      tooltips: {
        controls: false,
        seek: true,
      },
      storage: {
        enabled: true,
        key: 'plyr',
      },
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.5, 2],
      },
    });

    // Set the video source
    if (sourceUrl) {
      player.source = {
        type: 'video',
        sources: [
          {
            src: sourceUrl,
            type: 'video/mp4',
          },
        ],
      };
    }

    playerRef.current = player;

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [sourceUrl]);

  return (
    <div className="video-player-plyr">
      {/* Use the id attribute to match the CSS selector */}
      <video ref={videoElement} id="video-player" controls playsInline />
    </div>
  );
};

export default NPlayer;