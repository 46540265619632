import React, { useState, useEffect } from 'react';
import './VideoInfoInstructions.css';


function VideoInfoInstructions({ selectedProject }) {
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [videoTags, setVideoTags] = useState('');
  const [editingInstructions, setEditingInstructions] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const [error, setError] = useState('');
  const [submittedData, setSubmittedData] = useState(null);
  const [cancelButton, setCancelButton] = useState(false);
  const [isReadyToSend, setIsReadyToSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading animation
  const [isSendingToEditor, setIsSendingToEditor] = useState(false); // New state for sending to editor
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message
  const [videoVisibility, setVideoVisibility] = useState('');

  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');

  useEffect(() => {
    async function fetchSubmittedData() {
      try {
        const url = `https://backend.channellinkup.com/api/get-video-info/?project_name=${selectedProject}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
            'X-Auth-Type': authType, // Set the authentication type header
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setSubmittedData(data);
  
          if (data.title || data.description || data.tags || data.instructions) {
            setVideoTitle(data.title || '');
            setVideoDescription(data.description || '');
            setVideoTags(data.tags || '');
            setEditingInstructions(data.instructions || '');
            setVideoVisibility(data.visibility || 'public');
            setIsEditing(false);
            setCancelButton(true);
          } else {
            setVideoTitle('');
            setVideoDescription('');
            setVideoTags('');
            setEditingInstructions('');
            setVideoVisibility('public');
            setIsEditing(true);
            setCancelButton(false);
          }
        } else {
          console.error('Error fetching submitted video info:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching submitted video info:', error);
      }
    }
  
    fetchSubmittedData();
  }, [selectedProject]);
  

  useEffect(() => {
    if (!isEditing) {
      async function fetchSubmittedData() {
        try {
          const url = `https://backend.channellinkup.com/api/get-video-info/?project_name=${selectedProject}`;
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
              'X-Auth-Type': authType, // Set the authentication type header
            },
          });
  
          if (response.ok) {
            const data = await response.json();
            setSubmittedData(data);
          } else {
            console.error('Error fetching submitted video info:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching submitted video info:', error);
        }
      }
  
      fetchSubmittedData();
    }
  }, [isEditing, selectedProject]);
  

  const handleReadyToSend = () => {
    setIsReadyToSend(true);
    setError('');
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSubmitVideoInfo = async (e) => {
    e.preventDefault();
  
    if (!videoTitle || !editingInstructions || !videoVisibility) {
      setError('Please fill out all fields.');
      return;
    }
  
    setError('');
  
    try {
      setIsLoading(true); // Set loading state to true

      const url = 'https://backend.channellinkup.com/api/save-video-info/';
      const requestData = {
        project_name: selectedProject,
        title: videoTitle,
        description: videoDescription,
        tags: videoTags,
        instructions: editingInstructions,
        visibility: videoVisibility,
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        const data = await response.json();
        setSubmittedData(data);
        setIsEditing(false); // Switch to non-edit mode after submitting
        setCancelButton(true); // Display the Cancel button
      } else {
        setError('Error saving video info. Please try again later.');
        console.error('Error saving video info:', response.statusText);
      }
    } catch (error) {
      setError('Error saving video info. Please try again later.');
      console.error('Error saving video info:', error);
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };
  

  const handleSendToEditor = async () => {
    try {
      setIsSendingToEditor(true); // Set sending state to true
      setError('');
      const url = 'https://backend.channellinkup.com/api/send-notification-email/';
      const requestData = {
        project_name: selectedProject,
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        setSuccessMessage('Successfully sent to video editor.');
        setTimeout(() => {
          setSuccessMessage(''); // Clear success message after 5 seconds
          setIsReadyToSend(false);
        }, 5000); // Show success message for 5 seconds

        // Call backend API to set editing status to -1
        const setEditingStatusResponse = await fetch('https://backend.channellinkup.com/api/set-editing-status-owner/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${token}`,
            'X-Auth-Type': authType,
          },
          body: JSON.stringify({ project_name: selectedProject, status: -1 }),
        });

        if (!setEditingStatusResponse.ok) {
          console.error('Error setting editing status:', setEditingStatusResponse);
        }
      } else if (response.status === 404) {
        const responseData = await response.json();
        if (responseData.error === 'No editors found for this project.') {
          setError('No editor found for this project. Please add an editor.');
        } else {
          setError('Failed to send email. Please try again later.');
          console.error('Error sending email:', response);
        }
      } else {
        setError('Failed to send email. Please try again later.');
        console.error('Error sending email:', response);
      }
    } catch (error) {
      setError('An error occurred while sending the email. Please try again later.');
      console.error('Error sending email:', error);
    }
    finally {
      setIsSendingToEditor(false); // Set sending state back to false
    }
  };


  return (
    <div className="video-info-instructions">
      <h2>Video Info and Instructions</h2>
      {isEditing ? (
        <form onSubmit={handleSubmitVideoInfo}>
        <div className="form-input">
            <label htmlFor="videoTitle">Video Title:</label>
            <input
                type="text"
                id="videoTitle"
                value={videoTitle}
                onChange={(e) => setVideoTitle(e.target.value)}
            />
        </div>
    
        <div className="form-input">
            <label htmlFor="videoDescription">Video Description:</label>
            <textarea
                id="videoDescription"
                value={videoDescription}
                onChange={(e) => setVideoDescription(e.target.value)}
            />
        </div>
    
        <div className="form-input">
            <label htmlFor="videoTags">Video Tags:</label>
            <input
                type="text"
                id="videoTags"
                value={videoTags}
                onChange={(e) => setVideoTags(e.target.value)}
            />
        </div>

        <div className="form-input">
            <label htmlFor="videoVisibility">Video Visibility:</label>
            <select
              id="videoVisibility"
              value={videoVisibility}
              onChange={(e) => setVideoVisibility(e.target.value)}
              style={{ outline: 'none' }}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="unlisted">Unlisted</option>
            </select>
          </div>
    
        <div className="form-input">
            <label htmlFor="editingInstructions">Editing Instructions:</label>
            <textarea
                id="editingInstructions"
                value={editingInstructions}
                onChange={(e) => setEditingInstructions(e.target.value)}
            />
        </div>
    
        {error && <p className="error-message">{error}</p>}
    
        <div className="centered-button">
        <button type="submit" disabled={isLoading}>
              {isLoading ? (
                <div className="loading-spinner"></div>
              ) : (
                'Save'
              )}
            </button>
        </div>
    
        {cancelButton && submittedData && (
            <button type="button" onClick={handleCancelEdit}>
                Cancel
            </button>
        )}
    </form>
      ) : (
        <div>
          <p><strong>Video Title:</strong> {submittedData?.title}</p>
          <p><strong>Video Description:</strong> {submittedData?.description}</p>
          <p><strong>Video Tags:</strong> {submittedData?.tags}</p>
          <p><strong>Editing Instructions:</strong> {submittedData?.instructions}</p>
          <p><strong>Video Visibility:</strong> {submittedData?.visibility}</p>
          {cancelButton && submittedData && (
            <button onClick={handleEditButtonClick}>Edit</button>
          )}
        </div>
      )}

{!isEditing && !isReadyToSend && (
  <div className="centered-button">
    <button
      className="custom-button"
      type="button"
      disabled={!submittedData}
      onClick={handleReadyToSend}
    >
      Ready to Send to Editor
    </button>
  </div>
)}

{!isEditing && isReadyToSend && (
        <div className="ready-to-send-container">
          {!successMessage && (
            <div>
              <p className="ready-to-send-message">All set to send work to editor.</p>
              <div className="centered-button">
                {isSendingToEditor ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <div>
                    <button onClick={handleSendToEditor}>Send to Editor</button>
                  </div>
                )}
              </div>
            </div>
          )}
          {successMessage && <p className="success-message">{successMessage}</p>}
          {error && <p className="error-message">{error}</p>}
        </div>
      )}
    </div>
  );
}

export default VideoInfoInstructions;
