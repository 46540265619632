import React from 'react';
import aboutImage from '../images/flow.png'; // Import the image you want to use

function AboutSection() {
  const aboutImageStyles = {
    maxWidth: '100%',
    height: 'auto',
  };

  return (
    <section className="about-section">
      <div className="container">
        <h2 className="section-title">What We Do</h2>
        <div className="about-content">
          <div className="about-text">
            <p>
              Channel Linkup is a platform that simplifies the collaboration between YouTube channel owners and video editors. We provide a seamless workflow that helps you create, edit, and publish your videos with ease.
            </p>
            <p>
              Here's how it works:
            </p>
            <ol>
              <li>Channel owner uploads raw clips and instructions to our platform.</li>
              <li>Video editor accesses the clips, creates the perfect video, and submits it for review.</li>
              <li>Channel owner receives a notification and reviews the edited video.</li>
              <li>If approved, the video is automatically published to the YouTube channel.</li>
              <li>If changes are needed, the editor receives feedback and makes revisions.</li>
              <li>Once the video is approved, it's ready to reach your audience!</li>
            </ol>
          </div>
          <div className="about-image">
            <img src={aboutImage} alt="About Us" style={aboutImageStyles} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
