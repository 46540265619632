import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import './Header.css';


function Header({ onProjectChange, projectList }) {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [projectsDropdownOpen, setProjectsDropdownOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const { user, logout } = useAuth();
  const [email, setEmail] = useState(''); // Initialize email state
  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');

  useEffect(() => {
    fetchUserEmail(); // Fetch user email when the component mounts
    fetchProjects();
  }, [projectList]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        projectsDropdownOpen &&
        !event.target.closest('.projects-dropdown')
      ) {
        closeProjectsDropdown();
      }

      if (
        accountDropdownOpen &&
        !event.target.closest('.account-dropdown')
      ) {
        setAccountDropdownOpen(false);
      }
    };

    window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [projectsDropdownOpen, accountDropdownOpen]);

  const toggleProjectsDropdown = () => {
    setProjectsDropdownOpen(!projectsDropdownOpen);
  };

  const closeProjectsDropdown = () => {
    setProjectsDropdownOpen(false);
  };

  const fetchProjects = async () => {
    try {
      // Retrieve the JWT token from local storage

      const response = await fetch(`https://backend.channellinkup.com/api/get-projects/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType, // Set the authentication type header
        },
        //credentials: 'include', // Include credentials (cookies) for cross-origin requests
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      const projects = responseData.projects;
  
      setProjects(projects);
  
      if (projects.length > 0) {
        const lastProject = projects[projects.length - 1];
        setSelectedProject(lastProject);
        fetchProjectInfo(lastProject);
        onProjectChange(lastProject);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  
  const handleProjectChange = (projectName) => {
    setSelectedProject(projectName);
    fetchProjectInfo(projectName);
    // Notify parent component (UserDashboard) about the selected project change
    onProjectChange(projectName);
    closeProjectsDropdown(); // Close the dropdown when a project is clicked
  };

  const fetchProjectInfo = async (projectName) => {
    try {
      const url = `https://backend.channellinkup.com/api/get-project-info/?projectName=${projectName}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', // Adjust the headers if needed
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        // Handle the data and update your component state accordingly
      } else {
        console.error('Error fetching project info:', response.statusText);
        setSelectedProject('');
      }
    } catch (error) {
      console.error('Error fetching project info:', error);
      setSelectedProject('');
    }
  };
  

  const toggleAccountDropdown = () => {
    setAccountDropdownOpen(!accountDropdownOpen);
  };

  const fetchUserEmail = async () => {
    try {
      const response = await fetch('https://backend.channellinkup.com/api/get-user-email/', {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`, // Include the JWT token if required for authentication
          'X-Auth-Type': authType,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setEmail(data.email); // Update the email state
        //return data.email; // Assuming the backend returns an object with an 'email' property
      } else {
        console.error('Error fetching user email:', response.statusText);
        setEmail('Email not available');
        //return null; // Handle the error by returning null or an appropriate value
      }
    } catch (error) {
      console.error('Error fetching user email:', error);
      setEmail('Email not available');
      //return null; // Handle the error by returning null or an appropriate value
    }
  };
  

  return (
    <header className="header1">
      <div className="projects-dropdown">
        <button onClick={toggleProjectsDropdown}>
          {selectedProject || 'Projects'}
        </button>
        {projectsDropdownOpen && (
          <div className="projects-dropdown-content">
            {projects.length > 0 ? (
              projects.map((project) => (
                <button key={project} onClick={() => handleProjectChange(project)}>
                  {project}
                </button>
              ))
            ) : (
              <p>No projects</p>
            )}
          </div>
        )}
      </div>
      <Link className="navbar-brand1" to="/">
        Channel Linkup
      </Link>
      <nav className="account-nav">
        <div className="account-dropdown">
          <button onClick={toggleAccountDropdown}>
            My Account
          </button>
          {accountDropdownOpen && (
            <div className="dropdown-content">
              <p className="email">{email}</p>
              <Link to="/settings">
                <button>Account Settings</button>
              </Link>
              <Link to="/contact">
                <button>Contact Us</button>
              </Link>
              <button onClick={logout}>Logout</button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
