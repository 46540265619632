// ContactForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ContactForm.css'; // Import the CSS stylesheet
import { useAuth } from '../../AuthContext'; // Import the useAuth hook

function ContactForm({ onFormSubmit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const uniqueHash = localStorage.getItem("uniqueHash");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Start loading animation

    try {
      const response = await axios.post('https://formspree.io/f/mzblozrr', {
        name,
        email,
        message,
      });

      if (response.status === 200) {
        setSubmitted(true);
        onFormSubmit(); // Inform the parent component that the form is submitted
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop loading animation
    }
  };

  // Use useEffect to automatically redirect after 5 seconds
  useEffect(() => {
    if (submitted) {
      const redirectTimeout = setTimeout(() => {
        isAuthenticated ? navigate(`/dashboard-${uniqueHash}`) : navigate("/");
      }, 10000); // 10000 milliseconds (10 seconds)

      return () => clearTimeout(redirectTimeout); // Clear the timeout on unmount
    }
  }, [submitted, navigate]);

  return (
    <div>
      {submitted ? (
        <p className="submission-message">
          Thank you for your submission! We will be in touch.
        </p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          <button type="submit" className={loading ? 'loading' : ''}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </div>
  );
}

export default ContactForm;
