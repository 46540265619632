import React from 'react';

import Header from './Header';
import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import GetStartedSection from './GetStartedSection';
import AboutSection from './AboutSection';
import TestimonialsSection from './TestimonialsSection';
import Footer from './Footer';
import './Homepage.css'; // Import the CSS stylesheet

function Homepage() {
  return (
    <div className="homepage">
      <Header />
      <HeroSection />
      <FeaturesSection />
      <GetStartedSection />
      <AboutSection />
      <TestimonialsSection />
      <Footer />
    </div>
  );
}

export default Homepage;
