import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './Payment.css'; // Import the CSS file for styling

const stripePromise = loadStripe("pk_test_51OPoRBSFZJapdL5zxc9Kz1WNIDKY1LqQzRu3Wm0ChCqYv5iZiVawf81rszFdeDp2nmoMegRtNY99QgTLu59j3EaN00n76CA6jl");

const PaymentPage = () => {
  const [sessionUrl, setSessionUrl] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get('email');
  const [promoCode, setPromoCode] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [promoMessage, setPromoMessage] = useState('');

  // https://backend.channellinkup.com/api/create-checkout-session/
  const handlePayment = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
      });

      const data = await response.json();

      if (response.ok) {
        setSessionUrl(data.sessionUrl);
        if (data.sessionUrl) {
          window.location.href = data.sessionUrl; // Redirect to Stripe
        }
      } else {
        console.error('Error creating checkout session:', data.error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleApplyPromo = async () => {
    setIsApplyingPromo(true);
    try {
      const response = await fetch('https://backend.channellinkup.com/api/apply-promo-code/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ promoCode, userEmail }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.valid) {
          // Promo code is valid, update UI or state accordingly
          console.log('Promo code applied successfully');
          window.location.href = `/linked?success=true&token=${data.token}`;
        } else {
          if (!data.valid) {
            // Display a message to the user that the promo code has expired
            setPromoMessage('The promo code is invalid or expired.');
            setTimeout(() => {
                setPromoMessage('');
            }, 10000); // Clear the message after 10 seconds
        } else {
              console.error('Error applying promo code:', data.message);
          }
      }
      } else {
        console.error('Error applying promo code:', data.message);
      }
    } catch (error) {
      console.error('Error applying promo code:', error);
    } finally {
      setIsApplyingPromo(false);
    }
  };
  
  

return (
  <div className="payment-form-container">
    <div className="payment-box">
      {/* Display the message about account verification */}
      <p className="verification-message">[Our account is getting verified by Stripe. As soon as it is live, payments will be made available. Till then, you can access Channel Linkup if you have a promo code.]</p>
      <h2 className="payment-heading">Subscribe to Channel Linkup</h2>
      <div className="subscription-prices">
        <p className="subscription-price">$30 <span>/mo</span></p>
        <p className="or-text">or</p>
        <p className="subscription-price">$300 <span>/year (10% off)</span></p>
      </div>
      <button className={`payment-button ${isLoading ? 'loading' : ''}`} onClick={handlePayment}>
        {isLoading ? <div className="loading-circle1"></div> : 'Proceed to payment'}
      </button>
      <div className="promo-section">
        <div className="promo-text">Or use promo code</div>
        <div className="promo-input-container">
          <input
            className="promo-input"
            type="text"
            placeholder="Enter Promotion Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <button className={`apply-promo-button ${isApplyingPromo ? 'loading' : ''}`} onClick={handleApplyPromo}>
            {isApplyingPromo ? <div className="loading-circle1"></div> : 'Apply'}
          </button>
        </div>
        {promoMessage && <p className="promo-message">{promoMessage}</p>}
      </div>
    </div>
  </div>
);
};

const WrappedPaymentPage = () => (
  <Elements stripe={stripePromise}>
    <PaymentPage />
  </Elements>
);

export default WrappedPaymentPage;
