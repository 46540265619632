import React from "react";
import logo from "../images/main2.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faGlobe,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

function HeroSection() {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="hero-title">Welcome to Channel Linkup</h1>
            <p className="hero-description">
              <span className="highlighted-text">
                On a mission to revolutionize the way content creators collaborate.
              </span>
            </p>

            <p className="hero-benefit">
              <FontAwesomeIcon icon={faVideo} className="retro-icon" />
              Publish your video to YouTube while traveling or enjoying a pizza,
              all from the comfort of your phone.
            </p>
            <p className="hero-benefit">
              <FontAwesomeIcon icon={faGlobe} className="retro-icon" />
              Say goodbye to waiting for stable internet connections. Seamlessly
              manage and review your video submissions from anywhere.
            </p>
            <p className="hero-benefit">
              <FontAwesomeIcon icon={faComments} className="retro-icon" />
              Collaborate in real-time with your video editor. Provide feedback,
              request changes, and monitor progress all in one place.
            </p>
          </div>
          <div className="col-lg-6">
            <a href="/signup">
              <div className="gif-container">
                <img src={logo} alt="Channel Linkup" className="img-fluid" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
