import React, { useState, useEffect, useRef } from 'react';
import jwt_decode from 'jwt-decode';
import VideoPlayer from './nPlayer';
import "./FolderUpload.css";
import icon from './icons/icon.png';

function FolderUpload({ selectedProject }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const fileInputRef = useRef(null);


  const token = localStorage.getItem('token');
  const authType = localStorage.getItem('authType');

  const fetchS3Content = async () => {
    try {
      const response = await fetch(`https://backend.channellinkup.com/api/s3-folder-content-owner/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({ selectedProject }),
      });

      if (response.ok) {
        const data = await response.json();
        setContentData(data.contentData);
      } else {
        console.error('Error fetching S3 folder content:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching S3 folder content:', error);
    }
  };

  useEffect(() => {
    fetchS3Content(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchS3Content(); // Fetch content every 30 minutes
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    return () => clearInterval(intervalId); // Clean up interval
  }, []);

  const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      case 'avi':
        return 'video/x-msvideo';
      case 'wmv':
        return 'video/x-ms-wmv';
      case 'mkv':
        return 'video/x-matroska';
      case 'flv':
        return 'video/x-flv';
      case 'webm':
        return 'video/webm';
      case 'mpeg':
        return 'video/mpeg';
      case '3gp':
        return 'video/3gpp';
      case 'm4v':
        return 'video/x-m4v';
      default:
        return 'application/octet-stream'; // fallback MIME type
    }
  };

  const getFileName = (filePath) => {
    return filePath.split('/').pop();
  };

  const handleFileSelect = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };

  const removeSelectedFile = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  useEffect(() => {
    fetchS3Content(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchS3Content(); // Fetch content every 30 minutes
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    return () => clearInterval(intervalId); // Clean up interval
  }, [shouldRefresh]); // Add shouldRefresh as a dependency

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setSuccessMessage('Please choose a file to upload.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      return;
    }

    if (!selectedProject) {
      setSuccessMessage('Please create/select a project first.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => formData.append('files', file));

      formData.append('projectName', selectedProject); // Add the selected project name

      const response = await fetch('https://backend.channellinkup.com/api/upload-content/', {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'X-Auth-Type': authType,
        },
        body: formData,
      });

      if (response.ok) {
        // File uploaded successfully
        console.log('Upload successful');
        setSelectedFiles([]); // Clear selected files
        setSuccessMessage('Uploaded successfully');

        setShouldRefresh(!shouldRefresh);
        // Reset the file input value
        fileInputRef.current.value = '';
      } else {
        console.error('Upload error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
  };


  const handleDeleteFile = async (fileKey) => {
    try {
      const response = await fetch('https://backend.channellinkup.com/api/delete-file/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`, // Include the JWT token in the "Authorization" header
          'X-Auth-Type': authType,
        },
        body: JSON.stringify({ fileKey, selectedProject }),
      });

      if (response.ok) {
        // File deleted successfully
        setShouldRefresh(!shouldRefresh);
      } else {
        console.error('Error deleting file:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };


  return (
    <div className="folder-upload-section">
      <h2>Add Content</h2>
      <input ref={fileInputRef} type="file" multiple onChange={handleFileSelect} />
      <div className="selected-files-container">
        {selectedFiles.length > 0 && (
          <div>
            <p>Selected files:</p>
            <div className="selected-files-1">
              {selectedFiles.map((file, index) => (
                <div key={index} className="selected-file">
                  <p>{file.name}</p>
                  <span className="remove-file" onClick={() => removeSelectedFile(index)}>✖</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <button2 className={`upload-button1 ${isLoading ? 'loading' : ''}`} onClick={handleUpload}>
        {isLoading ? 'Uploading...' : 'Upload'}
      </button2>
      {successMessage && <p className="success-message">{successMessage}</p>}


      {contentData.length > 0 && (
        <div className="content-items-container">
          <h3>Content</h3>
          {contentData.map((item, index) => (
            <div key={index} className="content-item-2">
              {item.key.toLowerCase().endsWith('.mp4') || item.key.toLowerCase().endsWith('.mov') || item.key.toLowerCase().endsWith('.avi') || item.key.toLowerCase().endsWith('.wmv') || item.key.toLowerCase().endsWith('.mkv') || item.key.toLowerCase().endsWith('.flv') || item.key.toLowerCase().endsWith('.webm') || item.key.toLowerCase().endsWith('.mpeg') || item.key.toLowerCase().endsWith('.3gp') || item.key.toLowerCase().endsWith('.m4v') ? (
                <VideoPlayer sourceUrl={item.url} />
              ) : item.key.toLowerCase().endsWith('.png') || item.key.toLowerCase().endsWith('.jpg') || item.key.toLowerCase().endsWith('.jpeg') || item.key.toLowerCase().endsWith('.gif') ? (
                <img src={item.url} alt={item.key} />
              ) : (
                <div className="file-icon"> {/* Add a CSS class for styling */}
                  <img src={icon} alt="File Icon" />
                </div>
              )}
              <p className="file-name">{getFileName(item.key)}</p>
              <div className="file-actions">
                <button className="delete-file-button" onClick={() => handleDeleteFile(item.key)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FolderUpload;